$tab-border-radius: $border-radius;
$tab-border-color: darken($hairline, 5%);
$tab-hover-color: lighten($hairline, 0.5%);

ol.tabs {
  padding: 0;
  margin: 2rem 0;
  position: relative;
  border-bottom: 1px solid $tab-border-color;

  li {
    margin: 0;
    display: inline;

    a {
      padding: 10px 16px; // padding around the tab label
      position: relative;
      display: inline-block;
      bottom: -1px; // push the tab down so its bottom border covers the .nav-tabs border
      border-radius: $tab-border-radius $tab-border-radius 0 0;
    }

    a, a:visited {
      color: $heading-color;
    }

    a:hover {
      text-decoration: underline;
      background-color: $tab-hover-color;
    }

    &.active {
      a {
        border: 1px solid $tab-border-color;
        border-bottom: 1px solid $white; // white 'removes' the bottom border of the selected tab
      }

      a:hover {
        background: $white;
      }
    }
  }
}
