/*
 * Fix the Lanyon toggle button color bug.
 * https://github.com/codinfox/codinfox-lanyon/blob/094bbaa5049df641c20fe7c13418fc17b55be33c/_scss/component/_sidebar.scss#L127
 */

.sidebar-toggle:before {
  background-image: -webkit-linear-gradient(to bottom, $theme-color-dark, $theme-color-dark 20%, #fff 20%, #fff 40%, $theme-color-dark 40%, $theme-color-dark 60%, #fff 60%, #fff 80%, $theme-color-dark 80%, $theme-color-dark 100%);
  background-image:    -moz-linear-gradient(to bottom, $theme-color-dark, $theme-color-dark 20%, #fff 20%, #fff 40%, $theme-color-dark 40%, $theme-color-dark 60%, #fff 60%, #fff 80%, $theme-color-dark 80%, $theme-color-dark 100%);
  background-image:     -ms-linear-gradient(to bottom, $theme-color-dark, $theme-color-dark 20%, #fff 20%, #fff 40%, $theme-color-dark 40%, $theme-color-dark 60%, #fff 60%, #fff 80%, $theme-color-dark 80%, $theme-color-dark 100%);
  background-image:         linear-gradient(to bottom, $theme-color-dark, $theme-color-dark 20%, #fff 20%, #fff 40%, $theme-color-dark 40%, $theme-color-dark 60%, #fff 60%, #fff 80%, $theme-color-dark 80%, $theme-color-dark 100%);
}

.sidebar-toggle:active,
#sidebar-checkbox:focus ~ .sidebar-toggle,
#sidebar-checkbox:checked ~ .sidebar-toggle {
  background-color: $theme-color;
}

.sidebar-toggle:active:before,
#sidebar-checkbox:focus ~ .sidebar-toggle:before,
#sidebar-checkbox:checked ~ .sidebar-toggle:before {
  background-image: -webkit-linear-gradient(to bottom, #fff, #fff 20%, $theme-color-dark 20%, $theme-color-dark 40%, #fff 40%, #fff 60%, $theme-color-dark 60%, $theme-color-dark 80%, #fff 80%, #fff 100%);
  background-image:    -moz-linear-gradient(to bottom, #fff, #fff 20%, $theme-color-dark 20%, $theme-color-dark 40%, #fff 40%, #fff 60%, $theme-color-dark 60%, $theme-color-dark 80%, #fff 80%, #fff 100%);
  background-image:     -ms-linear-gradient(to bottom, #fff, #fff 20%, $theme-color-dark 20%, $theme-color-dark 40%, #fff 40%, #fff 60%, $theme-color-dark 60%, $theme-color-dark 80%, #fff 80%, #fff 100%);
  background-image:         linear-gradient(to bottom, #fff, #fff 20%, $theme-color-dark 20%, $theme-color-dark 40%, #fff 40%, #fff 60%, $theme-color-dark 60%, $theme-color-dark 80%, #fff 80%, #fff 100%);
}
