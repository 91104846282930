/* FONTS */
html {
  font-family: "proxima-nova", sans-serif;
  font-weight: 300;
}
@media (min-width: 38em){
  html { font-size: 18px; }
}

.container { max-width: 34rem; /* max-width: 28rem; */ }
@media (min-width: 38em) {
  .container { max-width: 36rem; /* max-width: 37rem; */ }
}
@media (min-width: 56em) {
  .container { max-width: 42rem; /* max-width: 32rem; */ }
}

a:hover, a:focus { text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  font-family: "lato", sans-serif;
  margin-bottom: .75rem;
  font-weight: 400;
}
h1 { font-size: 1.8rem; }
li { margin-bottom: .75rem; }
.post img { margin: 1rem auto; }

code, pre { font-family: "source-code-pro",sans-serif; font-weight: 300; }
pre { white-space: pre; overflow-x: auto; word-wrap: normal; background-color: #333; }
pre code { font-size: 90%; }
.highlight { color: #fff; }
.highlight .c, .highlight .c1 { color: #999; }
.highlight .err { background-color: transparent; }

.masthead { margin-bottom: 2.5rem; }
.masthead-title small { font-weight: 300; }
@media (min-width: 38em) {
	.masthead { margin-bottom: 3rem; }
}

.message { font-weight: 400; margin-bottom: 1.5rem; }

/* AVATAR */
img.avatar { border-radius: 50%; }

/* SIDEBAR */
.sidebar { font-family: "proxima-nova", sans-serif; }
.sidebar img.avatar { margin: 0 auto 1rem; }
.sidebar .sidebar-item.description, .sidebar .sidebar-item.description a { font-weight: 100; }
.sidebar .sidebar-item.copy { font-weight: 100; font-size: .65rem; }
.sidebar-nav { border-bottom: 0px none; }
.sidebar-nav-item { border-top: 0px none; }
.sidebar p.social { font-size: 1.15rem; }
.sidebar p.social a { margin: 0 12px 0 0; }

/* POST */
.post { clear: both; }
.page, .content>.post { margin-bottom: 6em; }
.post img.feature { margin: 0 1rem 3.5rem 0; }
.posts img.feature { width: 35%; float: left; }
/*.posts .summary.feature { margin-left: 35%; padding-left: 15px; }*/
.post img.avatar { display: inline; vertical-align: bottom; margin: 0; }
.post .post-date { font-family: "proxima-nova", sans-serif; font-size: 14px; margin-bottom: 1rem; }
.tweet-intent { display: block; text-align: center; font-family: "proxima-nova", sans-serif; font-size: 14px; font-weight: 300; }
.tweet-intent i { font-size: 32px; }
.post-navigation { margin-top: 3rem; padding-top: 1rem; border-top: 1px solid #eee; }
.post-navigation h2 { font-size: 18px; margin: 0; }
.post-navigation h3 { margin-top: 0; }
.post-navigation .left { float: left; width: 50%; margin-bottom: 3rem; }
.post-navigation .right { float: right; width: 50%; text-align: right; margin-bottom: 3rem; }


@media (max-width: 38em){
  h1 { font-size: 1.5rem; }
  .sidebar { font-size: .875rem; }
  .sidebar img.avatar { height: 100px; width: auto; }
  .content>.post img.feature { margin: 0 0 2rem 0; }
  .posts img.feature { float: none; width: 100%; margin: 0 0 1rem; }
}

@media (min-width: 38em) {
  .post .post-date { font-size: 16px; }
  .tweet-intent { padding: 0 20%; font-size: 18px; }
}