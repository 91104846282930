.page-navigation {
  width: auto;
  display: block;
  overflow: hidden;
  margin-bottom: 4em;

  a {
    width: 50%;
    float: left;
    margin: 1em 0;
    display: block;
  }

  .next {
    text-align: right;
  }
}
