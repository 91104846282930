ul.social {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
    font-size: 0.8em;
    margin-right: 0.8em;

    &:last-child {
      margin-right: 0;
    }
  }
}
