$sans-font-family: "PT Sans", Helvetica, Arial, sans-serif;

$white: #fff;
$blue: #00f;
$purple: #4B0082;
$hairline: #eee;
$heading-color: #313131;

// $theme-color: #6a9fb5;
// $theme-color: #303030;
// $theme-color: #8B008B;
$theme-color: #333;
$theme-color-dark: darken($theme-color, 5%);
$background-color: $white;

$link-color: darken($blue, 10%);
$visited-link-color: $purple;

$primary-color: #0275d8;
$success-color: #5cb85c;
$info-color:    #5bc0de;
$warning-color: #f0ad4e;
$danger-color:  #d9534f;

$primary-bg-color: lighten($primary-color, 10%);
$success-bg-color: lighten($success-color, 10%);
$info-bg-color:    lighten($info-color,    10%);
$warning-bg-color: lighten($warning-color, 10%);
$danger-bg-color:  lighten($danger-color,  10%);

$border-radius: 4px;
