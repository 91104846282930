#incremental-search {
  padding-bottom: 50px;
}

#incremental-search-icon {
  text-align: right;

  a {
    color: $heading-color;
    // color: #8CE329;
  }
}

#incremental-search-form {
  input {
    width: 100%;
    color: #aaa;
    padding: 0.5em;
    font-size: 1.1em;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
}

#incremental-search-results {
}

.hidden {
  display: none;
}
