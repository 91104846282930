.banner-ad {

  h2 {
    // color: orange;
  }

  img {
    padding: 0;
  }

  &.hide {
    display: none;
  }

  &.top {
    // border: 1px solid blue;

    img {
      margin: 0 0 3em 0;
    }
  }

  &.bottom {
    // border: 1px solid red;

    h2 {
      margin-top: 2em;
    }
  }
}
