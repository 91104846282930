/*
 * Custom Styles
 */

.masthead {
 border-bottom: 1px solid $hairline;

 .masthead-layout {
   display: flex;

   div {
     flex: 1;
   }
 }
}

.container a {
 color: $link-color;
}

.container a:visited {
 color: $visited-link-color;
}

.sidebar,
.sidebar-toggle:active,
#sidebar-checkbox:checked ~ .sidebar-toggle {
  background-color: $theme-color;
}

.sidebar-toggle {
  color: $theme-color;
}

.page-title,
.post-title,
.post-title a, .post-title a:visited,
.masthead-title a, .masthead-title a:visited {
  color: #303030;
}

.related {
  $related-padding: 1rem;
  padding-top: $related-padding;
  padding-bottom: $related-padding;
  margin-bottom: 2rem;
  border-bottom: 1px solid $hairline;
}

.pagination-item {
  border: 1px solid $hairline;
}

figure {
  margin: 0;
  padding: 0;
}

pre {
  background-color: #333;
}

pre code {
  font-size: 100%;
}

code, pre {
  font-family: Menlo, Monaco, "Courier New", monospace;
}

.post-date-inline {
  display: inline;
  color: #9a9a9a;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

li {
  margin: 0;
  padding: 0;
}

table.layout {
  border: none;
  tr, th, td {
    border: none;
  }
  tbody tr:nth-child(odd) td,
  tbody tr:nth-child(odd) th {
    background-color: $background-color;
  }
  td {
    text-align: center;
    img {
      margin: auto;
    }
  }

  &.vertical td {
    img {
       margin: 0;
    }
  }

  &.align-left td {
    text-align: left;
  }
}

.center {
  text-align: center;
  img {
    margin: auto;
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.indent {
  margin-left: 2em;
}

.no-bullets {
  list-style: none;
}

ul.publication {
  padding: 0;
  line-height: 1.6;
  list-style: none;
  margin-bottom: 1em;
}

.post {
  img {
    margin: 0;
  }
}

article.index + article.index {
  margin-top: 2.5em;
}

// Same as .post-date in poole.scss
.author {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}

#disqus_thread {
  margin-bottom: 4em;
}

figure pre table {
  border: none;

  td.code, td.gutter.gl {
    padding: 0;
    border: none;
  }
}

img.pull-left, div.pull-left {
  float: left;
  padding-right: 1em;
}
